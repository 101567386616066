import * as React from 'react';
import { SectionContainer } from '../section-container/section-container';
import { FullServiceGrid } from './fullservice-grid';
import './fullservice-section.scss';

/* const FullServiceDescription = styled.div`
  margin-bottom: 8rem;
  font-size: 1.25em;
` */

export const FullServiceSection: React.FunctionComponent = () => {
  return (
    <SectionContainer title="fullservice" className="fullservice-section" id="full-service">
      <div className="row justify-content-center mb-5">
        <div className="col-md-11">
          Als Einkaufsleitung und Produktmangement haben wir über viele Jahre erfolgreich Produktionsstrukturen für
          namhafte Firmen aufgebaut. Unser professionelles sourcing Know-How erstreckt sich über mehr als 20
          Produktionsländer. Heute konzentrieren wir uns vornehmlich auf die Türkei, Bangladesch, China Indonesien und
          Vietnam.
        </div>
      </div>

      <FullServiceGrid />
    </SectionContainer>
  );
};
