import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { FullServiceItem } from './full-service-item';
import './fullservice-grid.scss';

export const FullServiceGrid: React.FunctionComponent = () => {
  return (
    <div className="full-service-grid">
      <FullServiceItem
        title="creativity"
        callableImage={<StaticImage src="../../assets/images/service/creativity-rectangle.png" alt="" />}
        fullImage={<StaticImage aspectRatio={1} src="../../assets/images/service/creativity-square.png" alt="" />}
      >
        <ul>
          <li>Leidenschaft für die Entwicklung ihres Produkts</li>
          <li>Voller Support ihres Designteams bei der Kollektionsentwicklung</li>
          <li>Permanenter Input von neuen Trends</li>
          <li>Entwicklung von Qualitäten, Modellen, Drucken, Accessories</li>
          <li>Unterstützung durch unser professionelles Team</li>
        </ul>
      </FullServiceItem>

      <FullServiceItem
        title="sampling"
        callableImage={<StaticImage src="../../assets/images/service/sampling-rectangle.png" alt="" />}
        fullImage={<StaticImage aspectRatio={1} src="../../assets/images/service/sampling-square.png" alt="" />}
      >
        <ul>
          <li>Kreative und wirtschaftliche Platzierung der Musterung bei unseren ausgewählten Lieferanten</li>
          <li>Permanente Überwachung (oder Begleitung) von Prototypen, Verkaufs- und Fotomustern</li>
          <li>Sicherstellung von Qualität, Preis und Terminen</li>
        </ul>
      </FullServiceItem>

      <FullServiceItem
        title="workflow"
        callableImage={<StaticImage src="../../assets/images/service/workflow-rectangle.png" alt="" />}
        fullImage={<StaticImage aspectRatio={1} src="../../assets/images/service/workflow-square.png" alt="" />}
      >
        <ul>
          <li>Verantwortung des gesamten Produktionprozesses bis zur termingerechten Auslieferung</li>
          <li>Detailierte Erstellung eines Produktionsablaufplans, (inklusive leadtime,+ timeframe)</li>
          <li>Platzierung der Produktion bei unseren zertifizierten Partnern</li>
          <li>Kontrolle der gesamten Produktionskette, vom lab dip bis zum bestätigten Produktionsmuster</li>
        </ul>
      </FullServiceItem>

      <FullServiceItem
        title="price & quality"
        callableImage={<StaticImage src="../../assets/images/service/price-quality-rectangle.png" alt="" />}
        fullImage={<StaticImage aspectRatio={1} src="../../assets/images/service/price-quality-square.png" alt="" />}
      >
        <ul>
          <li>Hoher Qualitätsanspruch nach AQL Standard bei gleichzeitiger Preissensibilität</li>
          <li>Umsetzung ihrer Preisanforderungen bei unseren Lieferanten</li>
          <li>Durchführung regelmäßiger Audits zur Qualitätssicherung</li>
          <li>Gewährleistung höchster Qualität durch uns und unserer Partner</li>
        </ul>
      </FullServiceItem>

      <FullServiceItem
        title="speed sourcing"
        callableImage={<StaticImage src="../../assets/images/service/speed-sourcing-rectangle.png" alt="" />}
        fullImage={<StaticImage aspectRatio={1} src="../../assets/images/service/speed-sourcing-square.png" alt="" />}
      >
        <ul>
          <li>Permanenter Zugriff auf verfügbare Lagerbestände durch unser globales Netzwerk</li>
          <li>Angebot kurzfristiger und schneller Produktionsprozesse durch unsere langjährigen Partnerschaften</li>
        </ul>
      </FullServiceItem>

      <FullServiceItem
        title="production"
        callableImage={<StaticImage src="../../assets/images/service/production-hub-rectangle.png" alt="" />}
        fullImage={<StaticImage aspectRatio={1} src="../../assets/images/service/production-hub-square.png" alt="" />}
      >
        <ul>
          <li>Unsere ausgewählte Lieferanten Exspertise stellen wir Ihnen gerne zur Verfügung</li>
          <li>Europehub (Jersey, light Woven, Jersey, heavy Knits, light Woven, Denim)</li>
          <li>Asiahub (jersey, light woven, woven, denim, jackets, pants, outerwear, heavy knits, woven)</li>
        </ul>
      </FullServiceItem>
    </div>
  );
};
