import * as React from 'react';
import { SectionContainer } from '../section-container/section-container';
import { StaticImage } from 'gatsby-plugin-image';
import './who-section.scss';

export const WhoSection: React.FunctionComponent = () => {
  return (
    <SectionContainer title="who" className="who-section" id="who">
      <div className="image-container">
        <StaticImage src="../../../static/images/placeholder-human.png" alt="" />
      </div>

      <div className="description-container">
        <h2 className="text-uppercase">Petra Brinckmann</h2>
        <p>the quality woman</p>

        <p className="d-inline-flex flex-column">
          <span>Einkaufsleitung Produktmanagment</span>
          <span>DOB/HAKA/KIDS, Esprit, Mustang, JOOP!</span>
          <span>Jeans, Tom Tailor, Marco Polo</span>
        </p>

        <blockquote className="fst-italic fw-light">
          &quot;Qualität zeigt sich im Detail - und in jedem einzelnen Produktionsschritt&quot;
        </blockquote>
      </div>
    </SectionContainer>
  );
};
