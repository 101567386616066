import * as React from 'react';
import './footer-menu.scss';
import { Link } from 'gatsby';

export const FooterMenu: React.FunctionComponent = () => {
  return (
    <div className="footer-menu container">
      <Link to="/" className="footer-item">
        Datenschutzerklärung
      </Link>
      <Link to="/" className="footer-item">
        AGB
      </Link>
      <Link to="/" className="footer-item">
        Impressum
      </Link>
    </div>
  );
};
