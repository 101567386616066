/* eslint-disable react/no-children-prop */
import * as React from 'react';
import './full-service-item.scss';
import { BaseFullServiceModal } from '../full-service-modals/base-full-service-modal';
import { BackdropContext } from '../backdrop-container/backdrop-container';

interface FullServiceItemProps {
  title: string;
  callableImage: React.ReactNode;
  fullImage: React.ReactNode;
}

export const FullServiceItem: React.FunctionComponent<FullServiceItemProps> = ({
  children,
  fullImage,
  callableImage,
  title,
}) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);

  return (
    <BackdropContext.Consumer
      children={(backdrop) => {
        return (
          <div className="full-service-grid-item">
            <div
              className="item-callable"
              onClick={() => {
                setOpen(true);
                backdrop.setVisible(true);
              }}
            >
              <div className="image">{callableImage}</div>

              <div className="inner">
                <span className="inner-overlay">{title}</span>
              </div>
            </div>

            <div className="item-modal">
              {isOpen ? (
                <BaseFullServiceModal
                  name={title}
                  image={fullImage}
                  onRequestClose={() => {
                    setOpen(false);
                    backdrop.setVisible(false);
                  }}
                >
                  {children}
                </BaseFullServiceModal>
              ) : null}
            </div>
          </div>
        );
      }}
    />
  );
};
