import * as React from 'react';
import { ChangeEvent } from 'react';
import './contact-us-section.scss';
import { SectionContainer } from '../section-container/section-container';

const SuccessAlert: React.FunctionComponent<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div className="alert alert-success alert-dismissible fade show mb-4" role="alert">
      Vielen Dank für Ihre Kontaktanfrage! Wir werden uns schnellstmöglich bei Ihnen melden.
      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={onClose} />
    </div>
  );
};

const ErrorAlert: React.FunctionComponent<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div className="alert alert-danger alert-dismissible fade show mb-4" role="alert">
      Leider haben wir aktuell technische Probleme! Sie können uns auch jeder Zeit eine Email schreiben an{' '}
      <a href="mailto:info@pmsourcing.de">info@pmsourcing.de</a>.
      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={onClose} />
    </div>
  );
};

export const ContactUsSection: React.FunctionComponent = () => {
  const [isLoading, setLoading] = React.useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);
  const [showErrorAlert, setShowErrorAlert] = React.useState(false);

  const [formModel, setFormModel] = React.useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    setFormModel({
      ...formModel,
      [event.target.name as string]: event.target.value as string,
    });
  };

  const showSuccess = () => {
    setShowSuccessAlert(true);
    setTimeout(() => {
      setShowSuccessAlert(false);
    }, 5000);
  };

  const showError = () => {
    setShowErrorAlert(true);
    setTimeout(() => {
      setShowErrorAlert(false);
    }, 5000);
  };

  const resetForm = () => {
    setLoading(false);
    setFormModel({
      name: '',
      surname: '',
      email: '',
      phone: '',
      message: '',
    });
  };

  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch('http://localhost:5001/c-pmsourcing/us-central1/submitMessage', {
        method: 'POST',
        body: JSON.stringify(formModel),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        showSuccess();
        resetForm();
      } else {
        showError();
        resetForm();
      }
    } catch (ex) {
      showError();
      resetForm();
    }
  };

  return (
    <SectionContainer title="contact us" className="contact-us-section" id="contact-us">
      {showSuccessAlert ? <SuccessAlert onClose={() => setShowSuccessAlert(false)} /> : null}

      {showErrorAlert ? <ErrorAlert onClose={() => setShowErrorAlert(false)} /> : null}

      <form
        className="row g-3"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="col-md-6">
          <label className="form-label">Vorname*</label>
          <input
            name="name"
            type="text"
            className="form-control"
            autoComplete="given-name"
            required={true}
            disabled={isLoading}
            onChange={handleInputChange}
            value={formModel.name}
          />
        </div>
        <div className="col-md-6">
          <label className="form-label">Nachname*</label>
          <input
            name="surname"
            type="text"
            className="form-control"
            autoComplete="family-name"
            required={true}
            disabled={isLoading}
            onChange={handleInputChange}
            value={formModel.surname}
          />
        </div>
        <div className="col-md-6">
          <label className="form-label">E-Mail*</label>
          <input
            name="email"
            type="email"
            required={true}
            autoComplete="email"
            className="form-control"
            disabled={isLoading}
            onChange={handleInputChange}
            value={formModel.email}
          />
        </div>
        <div className="col-md-6">
          <label className="form-label">Telefonnummer*</label>
          <input
            name="phone"
            type="tel"
            className="form-control"
            autoComplete="tel"
            required={true}
            disabled={isLoading}
            onChange={handleInputChange}
            value={formModel.phone}
          />
        </div>

        <div className="col-md-12">
          <label className="form-label">Ihre Nachricht*</label>
          <textarea
            name="message"
            className="form-control"
            required={true}
            minLength={3}
            disabled={isLoading}
            onChange={handleInputChange}
            value={formModel.message}
          />
        </div>

        <div className="col-12">
          {isLoading ? (
            <button type="submit" className="btn btn-primary" disabled={isLoading}>
              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />
              Wird abgesendet...
            </button>
          ) : (
            <button type="submit" className="btn btn-primary" disabled={isLoading}>
              Absenden
            </button>
          )}
        </div>
      </form>
    </SectionContainer>
  );
};
