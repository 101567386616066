import * as React from 'react';
import { SectionContainer } from '../section-container/section-container';
import './about-section.scss';

export const AboutSection: React.FunctionComponent = () => {
  return (
    <SectionContainer title="about" className="about-section" isFirst id="about">
      <div className="row justify-content-center">
        <p className="col-md-8 fs-5">
          PM ist eine fullservice company mit Sitz in Hamburg. Unsere Textilkunden bieten wir maßgeschneiderte
          Produktlösungen für alle Produktgruppen und Zugang zu unserer über Jahre aufgebauten Beschaffungsmatrix in
          Europa und Asien - von der kreativen Entwicklung bis hin zur qualitativ, pünktlichen Lieferung.
        </p>
      </div>
    </SectionContainer>
  );
};
