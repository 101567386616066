import * as React from 'react';
import './backdrop-container.scss';

export interface BackdropContextType {
  isVisible: boolean;
  setVisible: (visible: boolean) => void;
}

export const BackdropContext = React.createContext<BackdropContextType>({
  isVisible: false,
  setVisible: () => {},
});

export const BackdropContainer: React.FunctionComponent = ({ children }) => {
  const [state, setState] = React.useState<boolean>(false);

  const onVisibilityChange = (visible: boolean) => {
    if (visible) {
      document.body.style.overflowY = 'hidden';
      setState(true);
    } else {
      document.body.style.overflowY = 'visible';
      setState(false);
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <BackdropContext.Provider value={{ isVisible: state, setVisible: onVisibilityChange }}>
      {state ? <div className="backdrop-container" /> : null}

      {children}
    </BackdropContext.Provider>
  );
};
