import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './learn-more.scss';
import { Link } from 'gatsby';

export const LearnMoreButton: React.FunctionComponent = () => {
  return (
    <Link className="learn-more-action" to="/#about">
      <span className="learn-more-title">Mehr erfahren</span>
      <FontAwesomeIcon icon={faChevronDown} />
    </Link>
  );
};
