/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, withPrefix } from 'gatsby';
import './header-menu.scss';
import { BackdropContext } from '../backdrop-container/backdrop-container';

const allMenuItems = [
  <Link className="menu-link" to="/#about" key="about">
    about
  </Link>,
  <Link className="menu-link" to="/#who" key="who">
    who
  </Link>,
  <Link className="menu-link" to="/#full-service" key="full-service">
    full service
  </Link>,
  <Link className="menu-link" to="/#sustainability" key="sustainability">
    sustainability
  </Link>,
  <Link className="menu-link" to="/#references" key="references">
    references
  </Link>,
  <Link className="menu-link" to="/#contact-us" key="contact-us">
    contact us
  </Link>,
];

export const HeaderMenu: React.FunctionComponent = () => {
  const [pageOffset, mutatePageOffset] = useState(0);

  useEffect(() => {
    const callback = () => mutatePageOffset(window.pageYOffset);
    document.addEventListener('scroll', callback);
    return () => document.removeEventListener('scroll', callback);
  });

  const isFullMenu = pageOffset >= 200;

  return (
    <div className={'header-menu ' + (isFullMenu ? 'has-background' : '')}>
      <div className="container d-flex">
        <Link className={'logo-container ' + (isFullMenu ? 'is-visible' : '')} to="/">
          <img src={withPrefix('/svgs/logo.svg')} alt="" />
        </Link>

        <div className="menu-inline-container">{allMenuItems}</div>

        <BackdropContext.Consumer
          children={(contextState) => {
            return (
              <div
                className="menu-split-container"
                onClick={() => {
                  contextState.setVisible(!contextState.isVisible);
                }}
              >
                <div className={'menu-burgers ' + (contextState.isVisible ? 'is-active' : '')}>
                  <div key="1" />
                  <div key="2" />
                  <div key="3" />
                </div>

                <div className={'items-dropdown ' + (contextState.isVisible ? 'is-open' : '')}>
                  <div className="container">{allMenuItems}</div>
                </div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};
