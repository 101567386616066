import * as React from 'react';
import { LearnMoreButton } from './learn-more';
import './hero.scss';
import { withPrefix } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

export const Hero: React.FunctionComponent = () => {
  let [isVisible, setVisible] = React.useState(false);

  let [isLoaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    const resizeListener = () => {
      const currentWidth = window.innerWidth;
      const breakpoint = 768;

      setVisible(currentWidth >= breakpoint);
    };

    window.addEventListener('resize', resizeListener);

    resizeListener();

    return () => window.removeEventListener('resize', resizeListener);
  });

  React.useEffect(() => {
    const script2 = document.createElement('script');

    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script2.async = true;

    document.body.appendChild(script2);

    script2.onload = function () {
      // @ts-ignore
      window._wq.push({
        id: 'mptvk1jqr3',
        onReady: () => {
          setLoaded(true);
        },
        options: {
          preload: 'auto',
          autoPlay: true,
          silentAutoPlay: 'allow',
          controlsVisibleOnLoad: false,
          doNotTrack: true,
          playbar: false,
          endVideoBehavior: 'loop',
          playButton: false,
          settingsControl: false,
          smallPlayButton: false,
          volumeControl: false,
          fullscreenButton: false,
          muted: true,
        },
      });
    };
  });

  return (
    <div className="hero-container">
      <StaticImage className="hero-background" src="../../assets/images/hero-background.png" alt="Background" />

      {isVisible ? (
        <div className={'player-wrapper ' + (isLoaded ? 'is-visible' : '')}>
          <div
            className="wistia_responsive_padding player-self"
            style={{ padding: '56.25% 0 0 0', position: 'relative' }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: '100%',
                left: '0',
                position: 'absolute',
                top: '0',
                width: '100%',
              }}
            >
              <div
                className="wistia_embed wistia_async_mptvk1jqr3 seo=false videoFoam=true"
                style={{ height: '100%', width: '100%' }}
              >
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="splash container">
        <img className="splash-image mb-5" src={withPrefix('/svgs/logo.svg')} alt="" />

        <h1>Let&apos;s go together</h1>
      </div>

      <div className="learn-more-wrapper">
        <LearnMoreButton />
      </div>
    </div>
  );
};
