import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
// @ts-ignore accepted because it's just a FontAwesome icon here...
import { faTimes } from '../../icons/faTimesLight';
import './base-full-service-modal.scss';

const ModalClose: React.FunctionComponent<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div className="base-modal-close" onClick={() => (onClick !== null ? onClick() : null)}>
      <FontAwesomeIcon icon={faTimes as any} />
    </div>
  );
};

export interface BaseFullServiceModalProps {
  image: React.ReactNode;
  name: string;
  onRequestClose: () => void;
}

export const BaseFullServiceModal: React.FunctionComponent<BaseFullServiceModalProps> = ({
  name,
  image,
  onRequestClose,
  children,
}) => {
  return (
    <div className="base-modal-wrapper" onClick={onRequestClose}>
      <div className="base-modal-content">
        <ModalClose onClick={onRequestClose} />

        <h2 className="base-modal-title">{name}</h2>

        <div className="base-modal-body">
          <div className="base-modal-body-text">{children}</div>
          <div className="base-modal-body-image">{image}</div>
        </div>
      </div>
    </div>
  );
};
