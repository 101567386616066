import * as React from 'react';
import './root-container.scss';
import { Helmet } from 'react-helmet';
import { BackdropContainer } from '../backdrop-container/backdrop-container';

export const RootContainer: React.FunctionComponent = ({ children }) => {
  return (
    <BackdropContainer>
      <Helmet>
        <title>PM Textile Sourcing</title>
      </Helmet>

      <div className="root-container">
        <div className="content">{children}</div>
      </div>
    </BackdropContainer>
  );
};
