import * as React from 'react';
import './section-container.scss';

interface SectionContainerProps {
  title: string;
  className?: string;
  isFirst?: boolean;
  id: string;
}

export const SectionContainer: React.FunctionComponent<SectionContainerProps> = ({
  children,
  className,
  title,
  isFirst,
  id,
}) => {
  return (
    <div className={'section-container container ' + className + ' ' + (isFirst === true ? 'is-first' : '')} id={id}>
      <h1 className="section-title">{title}</h1>
      <div className="section-content">{children}</div>
    </div>
  );
};
