import * as React from 'react';
import { SectionContainer } from '../section-container/section-container';
import { StaticImage } from 'gatsby-plugin-image';
import './sustainability-section.scss';

export const SustainabilitySection = () => {
  return (
    <SectionContainer title="sustainability" className="sustainability-section" id="sustainability">
      <div className="d-flex inner-wrapper">
        <StaticImage className="inner-image" src="../../assets/images/sustainability-cover.png" alt="" />

        <div className="inner-text">
          <p>
            PM textile sourcing steht für Kreativität, Qualität und Preis, immer im Einklang mit Eco values für die
            Entstehung eines besseren, nachhaltigen Produktes.
          </p>
          <p>
            Gemeinsam mit unseren Lieferanten übernehmen wir Verantwortung. Die Einhaltung der sozialen und ökologischen
            Standards bei der Produktion sowie die Langlebigkeit der Textilien Ihrer Kollektion sind uns eine
            Herzenssache!
          </p>
          <p>
            Wir arbeiten ausschließlich mit zertifizierten Betrieben, in denen internationale Arbeits- und
            Sozialstandards gewährleistet sind.
          </p>
          <p>
            Unsere Partner entwickeln und verbessern kontinuierlich ihre Produktionsbedingungen, um den
            Nachhaltigkeitszielen und IAO Standards der vereinten Nation zu entsprechen.
          </p>
        </div>
      </div>

      <div className="sustainability-logos">
        <StaticImage height={50} src="../../assets/images/sustainability/sustainability-higg-index.png" alt="" />
        <StaticImage height={50} src="../../assets/images/sustainability/sustainability-oeko-tex.png" alt="" />
        <StaticImage height={50} src="../../assets/images/sustainability/sustainability-bsci.png" alt="" />
        <StaticImage height={50} src="../../assets/images/sustainability/sustainability-organic-100.png" alt="" />
        <StaticImage height={50} src="../../assets/images/sustainability/sustainability-gots.png" alt="" />
        <StaticImage height={50} src="../../assets/images/sustainability/sustainability-bepi.png" alt="" />
      </div>
    </SectionContainer>
  );
};
