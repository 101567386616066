import * as React from 'react';
import { Hero } from '../components/hero/hero';
import { HeaderMenu } from '../components/header-menu/header-menu';
import { AboutSection } from '../components/index-sections/about-section';
import { WhoSection } from '../components/index-sections/who-section';
import { FullServiceSection } from '../components/index-sections/fullservice-section';
import { SustainabilitySection } from '../components/index-sections/sustainability-section';
import { ReferencesSection } from '../components/index-sections/references-section';
import { RootContainer } from '../components/root-container/root-container';
import { ContactUsSection } from '../components/index-sections/contact-us-section';
import { FooterMenu } from '../components/footer-menu/footer-menu';

const IndexPage = () => {
  return (
    <RootContainer>
      {/*  HeaderMenu is layered over all other components below. */}
      <HeaderMenu />

      <Hero />

      <AboutSection />
      <WhoSection />
      <FullServiceSection />
      <SustainabilitySection />
      <ReferencesSection />
      <ContactUsSection />

      <FooterMenu />
    </RootContainer>
  );
};

export default IndexPage;
