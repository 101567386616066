import * as React from 'react';
import { SectionContainer } from '../section-container/section-container';
import { StaticImage } from 'gatsby-plugin-image';
import './references-section.scss';

export const ReferencesSection = () => {
  return (
    <SectionContainer title="references" className="references-section" id="references">
      <div className="reference-logos">
        <StaticImage height={70} layout="fixed" quality={90} src="../../assets/images/references/basler.png" alt="" />
        <StaticImage height={50} layout="fixed" quality={90} src="../../assets/images/references/boss.png" alt="" />
        <StaticImage height={70} layout="fixed" quality={90} src="../../assets/images/references/mop.png" alt="" />
        <StaticImage
          height={50}
          layout="fixed"
          quality={90}
          src="../../assets/images/references/tom-tailor.png"
          alt=""
        />
        <StaticImage height={50} layout="fixed" quality={90} src="../../assets/images/references/mustang.png" alt="" />
        <StaticImage
          height={50}
          layout="fixed"
          quality={90}
          src="../../assets/images/references/joop-jeans.png"
          alt=""
        />
        <StaticImage height={50} layout="fixed" quality={90} src="../../assets/images/references/qvc.png" alt="" />
      </div>
    </SectionContainer>
  );
};
